export default [
    {
            id: "pos_0XRfqPWqHQPenjBqRZ",
            mail_info: {
                mail_receiver: "",
                mail_server: "",
                mail_port: "",
                mail_account: "",
                mail_user: "",
                mail_alias: "",
                mail_password: "",
                mail_ssl: false
            },
            odb_info: {
                issued_tracked_connection: "",
                issued_db_tracked: "",
                issued_book_db_tracked: "",
                recieved_tracked_connection: "",
                recieved_db_tracked: ""
            },
            custom_id: "POS001",
            name: "001",
            sending_way: 1,
            xml_store_path: "",
            txt_store_path: "",
            store_scheme: 1,
            sql_server: null,
            enable_dte_tracking: false,
            caf_admin: false,
            enable_error_alert: false,
            enable_caf_alert: false,
            min_caf_alert: 0,
            allow_pdf: false,
            pdf_copies: 1,
            pdf_copies_desc: "00",
            pdf_path: null,
            allow_printing: false,
            print_copies: 1,
            print_copies_desc: "00",
            caf_mode: false,
            issued_tracking_mode: 0,
            issued_tracking_type: 0,
            issued_tracking_path: null,
            recieved_tracking_mode: 0,
            recieved_tracking_type: 0,
            recieved_tracking_path: null,
            status: 1,
            status_reason: "",
            hmac: "00:15:5D:6B:CE:7B",
            created: "2023-06-27T12:19:58.259052-04:00",
            updated: "2023-06-27T12:19:58.259071-04:00",
            photo: null,
            branch: "bra_sEFQzOPf70HLCQCTy0"
    },
    {
        id: "pos_onyEhgI4eTonrxueoK",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS002",
        name: "002",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "00:15:5D:6B:CE:7B",
        created: "2023-06-27T12:18:37.382255-04:00",
        updated: "2023-06-27T12:18:37.382274-04:00",
        photo: null,
        branch: "bra_sEFQzOPf70HLCQCTy0"
    },
    {
        id: "pos_tgarV16ij64fatfFKY",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS003",
        name: "003",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:7B:E6:8A",
        created: "2023-06-16T11:06:13.254304-04:00",
        updated: "2023-07-28T13:10:58.603755-04:00",
        photo: null,
        branch: "bra_qEmqLQwAHuU3R5RrK1"
    },
    {
        id: "pos_rEB8sMvFrfXnBJGgnR",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS004",
        name: "004",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:7B:E6:8A",
        created: "2023-06-16T11:03:32.592583-04:00",
        updated: "2023-06-16T11:03:32.592604-04:00",
        photo: null,
        branch: "bra_qEmqLQwAHuU3R5RrK1"
    },
    {
        id: "pos_VcYeSMFEU9QlxJtbvq",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS005",
        name: "OO5",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:7B:E6:8A",
        created: "2023-06-13T13:14:41.480356-04:00",
        updated: "2023-06-13T13:14:41.480375-04:00",
        photo: null,
        branch: "bra_avEOWw1MD5LJ3vkq4b"
    },
    {
        id: "pos_SYVWflXI5Ao6b0bFiU",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS006",
        name: "006",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:FA:7E:6B",
        created: "2023-05-31T12:27:44.993954-04:00",
        updated: "2023-05-31T12:27:44.993974-04:00",
        photo: null,
        branch: "bra_avEOWw1MD5LJ3vkq4b"
    },
    {
        id: "pos_N8a0p2xHJ2TvYmjzkC",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS007",
        name: "007",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:FA:7E:6B",
        created: "2023-05-30T12:31:25.233818-04:00",
        updated: "2023-05-30T12:31:25.233838-04:00",
        photo: null,
        branch: "bra_eJwJ6HMVFXMiUP5CCU"
    },
    {
        id: "pos_qFwKXM8JVpMgXCWoSd",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS008",
        name: "008",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:FA:7E:6B",
        created: "2023-05-30T12:03:57.605926-04:00",
        updated: "2023-05-30T12:03:57.605945-04:00",
        photo: null,
        branch: "bra_eJwJ6HMVFXMiUP5CCU"
    },
    {
        id: "pos_GhfFXZ4KjquCTx91rY",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS009",
        name: "009",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08:00:27:FA:7E:6B",
        created: "2023-05-30T12:01:17.775861-04:00",
        updated: "2023-05-30T12:01:17.775881-04:00",
        photo: null,
        branch: "bra_iiP7s1WOFVkq8yhE44"
    },
    {
        id: "pos_SsXroCIty0WyvVz4lo",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS010",
        name: "010",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08-00-27-FA-7E-6B",
        created: "2023-05-15T21:54:41.647001-04:00",
        updated: "2023-05-15T21:54:41.647020-04:00",
        photo: null,
        branch: "bra_iiP7s1WOFVkq8yhE44"
    },
    {
        id: "pos_bTcHtCQSE88GGQqxSh",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS011",
        name: "011",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "08-00-27-FA-7E-6B",
        created: "2023-05-11T22:38:55.049853-04:00",
        updated: "2023-05-11T22:38:55.049874-04:00",
        photo: null,
        branch: "bra_3wYWiJN3xTbGhh4EnW"
    },
    {
        id: "pos_4ZcstGPUOzHPOoRbOA",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS012",
        name: "012",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "hmactest",
        created: "2023-05-10T16:42:13.612663-04:00",
        updated: "2023-05-10T16:42:13.612680-04:00",
        photo: null,
        branch: "bra_3wYWiJN3xTbGhh4EnW"
    },
    {
        id: "pos_QMq55vV3QqtpFuPEqV",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS013",
        name: "013",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "hmac822331",
        created: "2023-05-10T15:28:16.348594-04:00",
        updated: "2023-05-10T15:28:16.348611-04:00",
        photo: null,
        branch: "bra_IIB6BibCheTriIVjZN"
    },
    {
        id: "pos_yhpTMdrdKAoWbdyWd2",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS014",
        name: "014",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-10T13:48:35.123677-04:00",
        updated: "2023-05-10T13:48:35.123696-04:00",
        photo: null,
        branch: "bra_IIB6BibCheTriIVjZN"
    },
    {
        id: "pos_we1kIZ067UMPFEE8YG",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS015",
        name: "015",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-03T10:50:23.345723-04:00",
        updated: "2023-05-03T10:50:23.345742-04:00",
        photo: null,
        branch: "bra_o6wVWIfCipv5EcZwwg"
    },
    {
        id: "pos_OdZpszc9SbQXkwocfV",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS016",
        name: "016",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-03T10:43:25.608452-04:00",
        updated: "2023-05-03T10:43:25.608471-04:00",
        photo: null,
        branch: "bra_o6wVWIfCipv5EcZwwg"
    },
    {
        id: "pos_Bdg9MKcLi1J4dx7sAj",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS017",
        name: "017",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-03T10:30:28.297430-04:00",
        updated: "2023-05-03T10:30:28.297448-04:00",
        photo: null,
        branch: "bra_sEFQzOPf70HLCQCTy0"
    },
    {
        id: "pos_2fLSYs5BqkTo1vHKxr",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS018",
        name: "018",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-02T12:26:29.882752-04:00",
        updated: "2023-05-02T12:26:29.882770-04:00",
        photo: null,
        branch: "bra_sEFQzOPf70HLCQCTy0"
    },
    {
        id: "pos_q1DDyb2K60kKnR6Ki7",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS019",
        name: "019",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-02T12:23:07.492530-04:00",
        updated: "2023-05-02T12:23:07.492548-04:00",
        photo: null,
        branch: "bra_sEFQzOPf70HLCQCTy0"
    },
    {
        id: "pos_sfcZfWJpcHevzuQ45o",
        mail_info: {
            mail_receiver: "",
            mail_server: "",
            mail_port: "",
            mail_account: "",
            mail_user: "",
            mail_alias: "",
            mail_password: "",
            mail_ssl: false
        },
        odb_info: {
            issued_tracked_connection: "",
            issued_db_tracked: "",
            issued_book_db_tracked: "",
            recieved_tracked_connection: "",
            recieved_db_tracked: ""
        },
        custom_id: "POS020",
        name: "020",
        sending_way: 1,
        xml_store_path: "",
        txt_store_path: "",
        store_scheme: 1,
        sql_server: null,
        enable_dte_tracking: false,
        caf_admin: false,
        enable_error_alert: false,
        enable_caf_alert: false,
        min_caf_alert: 0,
        allow_pdf: false,
        pdf_copies: 1,
        pdf_copies_desc: "00",
        pdf_path: null,
        allow_printing: false,
        print_copies: 1,
        print_copies_desc: "00",
        caf_mode: false,
        issued_tracking_mode: 0,
        issued_tracking_type: 0,
        issued_tracking_path: null,
        recieved_tracking_mode: 0,
        recieved_tracking_type: 0,
        recieved_tracking_path: null,
        status: 1,
        status_reason: "",
        hmac: "C8:94:02:0C:FC:2B",
        created: "2023-05-01T14:38:37.593904-04:00",
        updated: "2023-05-01T14:38:37.593924-04:00",
        photo: null,
        branch: "bra_sEFQzOPf70HLCQCTy0"
    }
]
