export default [
    // {
    //     id: "bra_sEFQzOPf70HLCQCTy0",
    //     address: "Av. Dos De Mayo 1105",
    //     coordinates: [-12.086619760313937, -77.04348616667164],
    //     custom_id: "STORE01",
    //     name: "Farmacia - Av Dos de Mayo",
    //     photo: null, // +5114261899,
    //     created: "2023-06-16T11:03:32.581800-04:00",
    //     updated: "2023-07-28T13:08:22.667860-04:00",
    //     state: "PE010102",
    //     status: {
    //         code: 'Critico',
    //         percent: 95
    //     },
    //     pos: ['pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK']
    // },
    {
        id: "bra_qEmqLQwAHuU3R5RrK1",
        address: " Av. Canadá 886",
        coordinates: [-12.076638796014292, -77.01919339626512],
        custom_id: "STORE02",
        name: "Farmacia - Av. Canadá",
        photo: null, // null,
        created: "2023-06-13T13:14:41.470017-04:00",
        updated: "2023-06-13T13:14:41.470040-04:00",
        state: null,
        status: {
            code: 'Critico',
            percent: 80
        },
        pos: ['pos_tgarV16ij64fatfFKY', 'pos_rEB8sMvFrfXnBJGgnR']
    },
    {
        id: "bra_avEOWw1MD5LJ3vkq4b",
        address: "C. Las Tiendas 297",
        coordinates: [-12.098197212459203, -77.02021410090403],
        custom_id: "STORE03",
        name: "Farmacia - C. Las Tiendas",
        photo: null, // +5114261899,
        created: "2023-05-31T12:27:44.981669-04:00",
        updated: "2023-05-31T12:27:44.981691-04:00",
        state: null,
        status: {
            code: 'Critico',
            percent: 78
        },
        pos: ['pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU']
    },
    {
        id: "bra_eJwJ6HMVFXMiUP5CCU",
        address: "Av. Dos de Mayo NRO. 1607",
        coordinates: [-12.087018991143918, -77.04756898522736],
        custom_id: "STORE04",
        name: "Farmacia - Av. Dos de Mayo 2",
        photo: null, // +5113142020,
        created: "2023-05-30T12:31:25.225622-04:00",
        updated: "2023-05-30T13:48:44.557799-04:00",
        state: "PE010108",
        status: {
            code: 'Critico',
            percent: 76
        },
        pos: ['pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd']
    },
    {
        id: "bra_iiP7s1WOFVkq8yhE44",
        address: "Av. Arequipa 4651, Miraflores",
        coordinates: [-12.108177372219224, -77.02837973801549],
        custom_id: "STORE05",
        name: "Farmacia - Plaza Vea Miraflores",
        photo: null, // +5113142020,
        created: "2023-05-30T12:01:17.758233-04:00",
        updated: "2023-05-30T12:03:00.805146-04:00",
        state: "PE010102",
        status: {
            code: 'Medio',
            percent: 73
        },
        pos: ['pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo']
    },
    {
        id: "bra_3wYWiJN3xTbGhh4EnW",
        address: "Av. Palermo 478",
        coordinates: [-12.071866975081386, -77.02464481298306],
        custom_id: "STORE06",
        name: "Farmacia - Av. Palermo",
        photo: null, // +5114261899,
        created: "2023-05-15T21:54:41.624016-04:00",
        updated: "2023-05-15T21:54:41.624041-04:00",
        state: null,
        status: {
            code: 'Medio',
            percent: 52
        },
        pos: ['pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA']
    },
    {
        id: "bra_IIB6BibCheTriIVjZN",
        address: "Av. Gral. Antonio Álvarez de Arenales 2290",
        coordinates: [-12.079207053938129, -77.0344605745192],
        custom_id: "STORE07",
        name: "Farmacia - Av. Gral. Antonio Álvarez de Arenales",
        photo: null, // +5114261899,
        created: "2023-05-10T16:42:13.604089-04:00",
        updated: "2023-05-31T10:03:03.040572-04:00",
        state: null,
        status: {
            code: 'Normal',
            percent: 38
        },
        pos: ['pos_tgarV16ij64fatfFKY', 'pos_yhpTMdrdKAoWbdyWd2']
    },
    {
        id: "bra_o6wVWIfCipv5EcZwwg",
        address: "Av. Caminos del Inca 483",
        coordinates: [-12.10715397290968, -76.98769135778812],
        custom_id: "STORE08",
        name: "Farmacia - Av. Caminos del Inca",
        photo: null, // +5112434711,
        created: "2023-05-10T15:28:16.340238-04:00",
        updated: "2023-05-10T15:28:16.340263-04:00",
        state: null,
        status: {
            code: 'Normal',
            percent: 34
        },
        pos: ['pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV']
    },
    {
        id: "bra_sEFQzOPf70HLCQCTy0",
        address: "Av. José Pardo NRO. 620",
        coordinates: [-12.112234916960317, -77.03734756320631],
        custom_id: "STORE09",
        name: "Farmacia - Av. José Pardo",
        photo: null, // +5112434711,
        created: "2023-04-26T15:15:32.595075-04:00",
        updated: "2023-05-08T11:32:43.019689-04:00",
        state: "PE010101",
        status: {
            code: 'Normal',
            percent: 12
        },
        pos: ['pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o']
    }
]